import React from "react";

export default function Footer() {
  return (
    <footer>
      <div className="container">
        <p>&copy; globcon 2025. All Rights Reserved.</p>
        <ul className="list-inline">
          <li className="list-inline-item">
            <a href="./privacypolicy">Privacy</a>
          </li>
          <li className="list-inline-item">
            <a href="/imprint">Imprint</a>
          </li>
        </ul>
      </div>
    </footer>
  );
}
